import React from 'react'
import "./despre.scss"

const DespreCard = () => {

    return (
        <div className="despre_content">
            <div className="despre_card">
                <h1>MAISON DESIGN</h1>
                <p>Cu o experiență de peste 13 ani în amenajări interioare, Maison Design aduce în prim plan eleganța și rafinamentul <span>Porcelanosa</span> prin elementele care satisfac orice exigență legată de Inovație, Exclusivism și Calitate. Alături de designerii noștri reușim să dăm fiecărui interior personalitate.</p>
            </div>
        </div>
    )
}

export default DespreCard